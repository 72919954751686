<template>
  <v-card class="pa-8" width="100%">
    <h1 style="font-weight: 800; color: #e53935">ข้อมูลผู้ใช้งาน</h1>
    <v-row>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">prename</p></v-col
      >
      <v-col cols="4">
        <v-select
          v-model="data.prename"
          :items="prenameItems"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6"></v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">name</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.name"
          label="name"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">surname</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.surname"
          label="surname"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">nickname</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.nickname"
          label="nickname"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">empCode</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.empCode"
          label="empCode"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">ID card No.</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.idNo"
          label="ID card No."
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">School</p></v-col
      >
      <v-col cols="4">
        <v-combobox
          v-model="data.school"
          :items="schoolItems"
          outlined
          dense
        ></v-combobox>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Level</p></v-col
      >
      <v-col cols="4">
        <v-select
          v-model="data.level"
          :items="levelItems"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Section</p></v-col
      >
      <v-col cols="4">
        <v-combobox
          v-model="data.section"
          :items="sectionItems"
          outlined
          dense
        ></v-combobox>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Room</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.room"
          label="Room"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Mobile</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.mobile"
          label="Mobile"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Email</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.email"
          label="Email"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">
          Account Number
        </p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.accountNo"
          label="Account Number"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Status</p></v-col
      >
      <v-col cols="4">
        <v-select
          v-model="data.status"
          :items="StatusItems"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Start Date</p></v-col
      >
      <v-col cols="4">
        <v-menu
          ref="menufrom"
          v-model="menufrom"
          :close-on-content-click="false"
          :return-value.sync="data.StartDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="data.StartDate"
              label="วันที่เริ่ม"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="data.StartDate" no-title scrollable>
            <v-btn text color="primary" @click="menufrom = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menufrom.save(data.StartDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">End Date</p></v-col
      >
      <v-col cols="4">
        <v-menu
          ref="menuto"
          v-model="menuto"
          :close-on-content-click="false"
          :return-value.sync="data.endDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="data.endDate"
              label="วันที่สิ้นสุด"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="data.endDate" no-title scrollable>
            <v-btn text color="primary" @click="menuto = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuto.save(data.endDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Advisor Name</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.advisorName"
          label="Advisor Name"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">
          Advisor Mobile
        </p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.advisorMobile"
          label="Advisor Mobile"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Behavior 1</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.behavior1"
          label="Behavior 1"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Behavior 2</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.behavior2"
          label="Behavior 2"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Behavior 3</p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="data.behavior3"
          label="Behavior 3"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6"></v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="text-right">Photo</p></v-col
      >
      <v-col cols="6">
        <v-card outlined>
          <v-row no-gutters align="center">
            <v-col cols="10" md="10" sm="10" xs="10" class="ma-4" id="step-3">
              <span>เพิ่มรูป</span>
              <span style="color: red">*</span>
              <br />
              <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span>
              <v-img :src="showImage" width="200px"></v-img>
              <v-text-field
                v-model="picture_1"
                @click="changePic1()"
                :placeholder="imageName1"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure1"
                accept="image/png"
                @change="showPicture1($event)"
                style="display: none"
            /></v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-btn @click="submit()">บันทึก</v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn @click="submit()">บันทึก</v-btn> -->
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      prenameItems: ["นาย", "นาง", "นางสาว"],
      levelItems: ["ปวช", "ปวส", "ป ตรี"],
      sectionItems: ["ไฟฟ้ากำลัง", "ช่างยนต์", "ช่างกล", "เครื่องกลอุตสาหกรรม"],
      schoolItems: [],
      StatusItems: ["Activate", "Left", "Quarantine"],
      menufrom: false,
      menuto: false,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      data: {
        id: "",
        prename: "",
        name: "",
        surname: "",
        nickname: "",
        empCode: "",
        idNo: "",
        school: "",
        level: "",
        section: "",
        room: "",
        mobile: "",
        email: "",
        accountNo: "",
        status: "",
        startDate: "",
        endDate: "",
        advisorName: "",
        advisorMobile: "",
        profileImg: "",
        behavior1: "",
        behavior2: "",
        behavior3: "",
      },
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.data = userdata;
    this.showImage = userdata.profileImg
    // this.id = userdata.id;
    // this.username = userdata.username;
    // this.password = userdata.password;
    // this.firstname = userdata.firstname;
    // this.lastname = userdata.lastname;
    // this.nickName = userdata.nickName;
    // this.tel = userdata.tel;
    // this.email = userdata.email;
    // this.address = userdata.address;
    // this.postalCode = userdata.postalCode;
    // this.type = userdata.type;
    // this.activeFlag = userdata.activeFlag;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.profileImg = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageUser");
    },
    async submit() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("userSRW")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/` + this.data.id,
        this.data,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขผู้ใช้งานสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("ManageUser");
      } else {
        this.$swal.fire({
          icon: "error",
          text: `แก้ไขผู้ใช้งานไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>